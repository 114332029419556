<template>
  <main>

    <section class="section">
      <div class="container2">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
            <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
            <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
            <sqr-crumb name="relation-management" :bid="bid" :rid="rid">{{$t('breadcrumb_relation_management')}}</sqr-crumb>
          </ul>
        </nav>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <sqr-input-text class="field" label="relation_assistant" :value="relation.assistant" @change="fieldSet({field: 'assistant', value: $event})"/>
            <sqr-input-text class="field" label="relation_manager" :value="relation.manager" @change="fieldSet({field: 'manager', value: $event})"/>
            <sqr-input-text class="field" label="relation_intermediary" :value="relation.intermediary" @change="fieldSet({field: 'intermediary', value: $event})"/>
            <sqr-error-note :error="sendError"/>
            <div class="buttons">
              <sqr-button label="buttons_cancel" @click="$router.back()"/>
              <sqr-button label="buttons_save" color="primary" @click="saveAndGo()" :is-loading="sending"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

import SqrButton from '../sqrd/SqrButton';
import SqrInputText from '../sqrd/SqrInputText';

export default {
  name: 'RelationManagement',
  components: {SqrInputText},
  props: {
    bid: String,
    rid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', ['loading', 'loadError', 'sending', 'sendError']),
    ...mapGetters('relation', {relation: 'record'}),
    title () {
      if (!this.relation) return '';
      return this.$t('relation_title', Object.assign({}, this.relation, {
        status: this.$t('relation_status_' + this.relation.status)
      }));
    },
  },
  mounted () {
    this.sub({path: ['brokers', this.bid], id: this.id});
  },
  methods: {
    ...mapMutations('relation', ['fieldSet']),
    ...mapActions('relation', ['sub', 'send']),
    saveAndGo () {
      this.send().then(() => this.$router.back());
    }
  }
}
</script>
